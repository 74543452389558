import { clearFormData } from "./src/components/manager/form"

const onRouteUpdate = ({ location }) => {
  if (location.pathname !== '/register/') { // This will clear registration form data when leaving register page
    clearFormData()
  }
}

export {
  onRouteUpdate
}